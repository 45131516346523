<template>
    <div class="home-details-layout">
        <div v-if="title" class="home-details-title">
            <div class="title">{{ title }}</div>
            <div class="sub-title">{{ subTitle }}</div>
        </div>
        <div class="home-details-layout-inner">
            <!-- 支持页面内容左右反向显示 -->
            <div class="home-details-grid-container empty" v-if="isReverse"></div>
            <div v-for="(cataItem, itemIndex) in catalogueList" :key="itemIndex" class="home-details-grid-container">
                <div class="home-details-info-cointainer">
                    <!-- 暂无能力检查影响范围 目前只用于 AI页面 -->
                    <div class="home-details-info" v-if="renderHtml">
                        <div class="home-details-info-subtitle" v-html="$t(cataItem.categoryText)"></div>
                        <div class="home-details-info-title" v-html="$t(cataItem.itemName)"></div>
                        <div class="home-details-info-des" v-html="$t(cataItem.itemDes)"></div>
                    </div>
                    <div class="home-details-info" v-else>
                        <div class="home-details-info-subtitle">{{ $t(cataItem.categoryText) }}</div>
                        <div class="home-details-info-title">{{ $t(cataItem.itemName) }}</div>
                        <div class="home-details-info-des">{{ $t(cataItem.itemDes) }}</div>
                    </div>
                    <div v-if="cataItem.to" class="home-details-link">
                        <CommonComponentsButtonLine
                            :button-text="cataItem.linkButtonText"
                            @click="pushRouter(cataItem.to)"
                        />
                    </div>
                </div>
                <div class="home-details-media-container">
                    <!-- 视频设置静音以及循环播放 -->
                    <CommonComponentsVideoPlayer
                        :id="`home-details-media-video-${itemIndex}`"
                        class="home-details-media-video"
                        :sources="[
                            {
                                src: cataItem.mediaPath,
                                type: 'video/mp4',
                            },
                        ]"
                        :poster="cataItem.poster"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
    import { useI18n } from 'vue-i18n';
    import { gsap } from 'gsap';
    import { ScrollTrigger } from 'gsap/all';
    import { addLeftEnter, addRightEnter } from '~~/composables/useScrollTrigger';
    import { pushRouter } from '~~/composables/useRouterController';
    import { matchMobile } from '~~/composables/useMatchRules.ts';

    const { t } = useI18n();

    gsap.registerPlugin(ScrollTrigger);

    defineProps<{
        title?: string;
        subTitle?: string;
        isReverse?: boolean;
        renderHtml?: boolean;
        catalogueList: Array<CataItem>;
    }>();

    // const formatedDetailName = computed(
    //     () => (index: number) => t(detailItemList[index].itemName).split(' ').join('<br/>'),
    // );
    // addToVideoElmt('.home-details-media-video');
    const initPC = (): Array<ScrollTrigger> => {
        return [
            ...addLeftEnter(
                '.home-details-grid-container:nth-child(2n) > .home-details-info-cointainer, .home-details-grid-container:nth-child(2n+1) > .home-details-media-container',
            ),
            ...addRightEnter(
                '.home-details-grid-container:nth-child(2n+1) > .home-details-info-cointainer, .home-details-grid-container:nth-child(2n) > .home-details-media-container',
            ),
        ];
    };
    const initMobile = (): Array<ScrollTrigger> => {
        return [...addSlideInUp('.home-details-info-cointainer,.home-details-media-container')];
    };
    onMounted(() => {
        const ifMatched = matchMobile();
        if (!ifMatched) {
            initPC();
        } else {
            initMobile();
        }
        // store.$subscribe(() => {
        //     scrollTriggerList.forEach((scrollTriggerItem) => scrollTriggerItem.kill());
        //     debounce(init, 500);
        // });
    });
</script>

<style lang="less" scoped>
    .home-details-layout {
        background-color: #262626;
        border-radius: 40px;
        width: 100%;
        color: #000000;
        overflow: hidden;
        font-family: Montserrat, 'HarmonyOS Sans SC';
        .home-details-link {
            :deep(.button-flex-container) {
                width: .convert(133px) [ @vw];
                height: .convert(48px) [ @vw];
            }
        }

        @media (max-width: @screen-mobile-width) {
            .home-details-link {
                display: none !important;
            }
        }

        .home-details-layout-inner {
            margin: 0 auto;
            padding: .convert(60px) [ @vw];
            background: #ffffff;
            border-radius: 40px;

            @media (max-width: @screen-mobile-width) {
                padding: .convert(30px) [ @vw-mobile] .convert(20px) [ @vw-mobile] !important;
                border-radius: 20px;
            }
        }
        // .home-details-grid-container.reverse {
        //     &:nth-child(2n) {
        //         .home-details-info-cointainer {
        //             grid-column: 2;
        //         }

        //         .home-details-media-container {
        //             grid-column: 1;
        //         }
        //     }
        //     &:nth-child(2n + 1) {
        //         .home-details-info-cointainer {
        //             grid-column: 1;
        //         }

        //         .home-details-media-container {
        //             grid-column: 2;
        //         }
        //     }
        // }
        .home-details-grid-container {
            display: grid;
            grid-template-columns: 7fr 5fr;
            grid-template-rows: 1fr;
            grid-column-gap: .convert(60px) [ @vw];
            grid-row-gap: .convert(80px) [ @vw];
            grid-auto-flow: dense; //自动填充 先列后行

            @media (max-width: @screen-mobile-width) {
                display: flex;
                flex-direction: column;
                gap: .convert(15px) [ @vw-mobile];
            }

            &.empty {
                display: none;
            }

            &:nth-child(2n + 1) {
                grid-template-columns: 5fr 7fr;

                .home-details-info-cointainer {
                    grid-column: 2;
                }

                .home-details-media-container {
                    grid-column: 1; //grid-column-start:2 grid-column-end: 3; 从第2根列线到第3根列线，跨越一个可以省略后面的/3  2/3
                }
            }

            &:nth-child(n + 2) {
                //从第三个开始
                margin-top: .convert(80px) [ @vw];
            }

            .home-details-media-container {
                width: .convert(724px) [ @vw];
                height: .convert(407px) [ @vw];
                font-size: 64px;
                background: #d9d9d9;

                display: flex;
                justify-content: center;
                align-items: center;

                video {
                    width: 100%;
                }
                @media (max-width: @screen-mobile-width) {
                    width: .convert(305px) [ @vw-mobile];
                    height: .convert(172px) [ @vw-mobile];
                }
            }

            .home-details-info-cointainer {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                .home-details-info-subtitle {
                    font-weight: 500;
                    font-size: .convert(40px) [ @vw];
                    line-height: normal;
                    @media (max-width: @screen-mobile-width) {
                        font-size: .convert(16px) [ @vw-mobile] !important;
                        line-height: 160%;
                    }
                }

                .home-details-info-title {
                    font-weight: 500;
                    font-size: .convert(@font-size-title-xxl) [ @vw];
                    line-height: 100%;

                    @media (max-width: @screen-mobile-width) {
                        font-size: .convert(@font-size-title-xs) [ @vw-mobile];
                        line-height: 100%;
                        line-height: normal;
                    }
                }

                .home-details-info-des {
                    font-size: .convert(@font-size-lg) [ @vw];
                    line-height: 1.6;
                    margin-top: .convert(12px) [ @vw];
                    font-family: 'HarmonyOS Sans SC';

                    @media (max-width: @screen-mobile-width) {
                        font-size: .convert(12px) [ @vw-mobile] !important;
                        line-height: 100%;
                        margin-top: 5px;
                    }
                }
            }
        }

        .home-details-title {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin-top: 60px;

            .title {
                font-size: 68px;
                font-weight: 500;
            }
            .sub-title {
                width: 988px;
                font-size: 24px;
                color: #fff;
                text-align: center;
            }
        }
    }
</style>
